<script setup lang="ts">
import { useUserStore } from '@/stores/userStore';
import type { IApiError } from '@/types/types';
import { logout } from '@/utils/timeboxApi';
import { useRouter } from 'vue-router';
import { useMutation } from '@tanstack/vue-query';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';

const router = useRouter();
const userStore = useUserStore();

const { isPending, mutate } = useMutation<unknown, IApiError>({
	mutationFn: () => logout(),
	onSuccess: () => {
		userStore.isLoggedIn = false;
		userStore.user = null;
		router.push('/');
	},
});
</script>

<template>
	<div class="flex items-center gap-2">
		<template v-if="userStore.isLoggedIn">
			<Avatar icon="pi pi-user" style="background-color: #dee9fc; color: #1a2551" />
			<div class="username">{{ userStore.user?.name }}</div>
			<Button label="Logout" severity="secondary" text @click="mutate()" :disabled="isPending" />
		</template>

		<template v-else>
			<Button label="Login" severity="secondary" text as="router-link" to="/" />
			<Button label="Register" severity="secondary" outlined as="router-link" to="/register" />
		</template>
	</div>
</template>

<style scoped></style>
