import axios from 'axios';
import type { ITimebox, IUser } from '@/types/types';
import type { ITimeboxSchema } from '@/utils/schemas';

const api = axios.create({
	baseURL: 'https://timebox-api.bladesheng.com',
	// baseURL: 'http://localhost:8000',
});

api.defaults.withCredentials = true;

export async function getCsrfCookie() {
	return await api.get('/sanctum/csrf-cookie');
}

export async function login(loginDto: { email: string; password: string }) {
	await getCsrfCookie();

	return await api.post('/login', loginDto, {
		withXSRFToken: true,
	});
}

export async function register(registerDto: { name: string; email: string; password: string }) {
	await getCsrfCookie();

	return await api.post('/register', registerDto, {
		withXSRFToken: true,
	});
}

export async function logout() {
	await getCsrfCookie();

	return await api.post('/logout', null, {
		withXSRFToken: true,
	});
}

export async function getUser() {
	return await api.get<IUser>('/api/user');
}

export async function getTimeboxes() {
	return await api.get<ITimebox[]>('/api/timeboxes');
}

export async function createTimebox(timeboxDto: ITimeboxSchema) {
	await getCsrfCookie();

	return await api.post('/api/timeboxes', timeboxDto, {
		withXSRFToken: true,
	});
}

export async function updateTimebox(id: number, timeboxDto: ITimeboxSchema) {
	await getCsrfCookie();

	return await api.patch(`/api/timeboxes/${id}`, timeboxDto, {
		withXSRFToken: true,
	});
}

export async function deleteTimebox(timeboxId: number) {
	await getCsrfCookie();

	return await api.delete(`/api/timeboxes/${timeboxId}`, {
		withXSRFToken: true,
	});
}
