import './assets/main.css';

import { createApp } from 'vue';

import { createPinia } from 'pinia';

import App from './App.vue';

import router from './router';

import { VueQueryPlugin } from '@tanstack/vue-query';

import 'primeicons/primeicons.css';
import PrimeVue, { defaultOptions } from 'primevue/config';
import Aura from '@primevue/themes/aura';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueQueryPlugin);
app.use(PrimeVue, {
	theme: {
		preset: Aura,
		options: {
			darkModeSelector: '.dark',
			cssLayer: {
				name: 'primevue',
				order: 'tailwind-base, primevue, tailwind-utilities',
			},
		},
	},
	locale: {
		...defaultOptions.locale,
		firstDayOfWeek: 1,
	},
});
app.use(ToastService);
app.use(ConfirmationService);

app.mount('#app');
