<script setup lang="ts">
import { RouterLink } from 'vue-router';
import UserDetails from '@/components/UserDetails.vue';
import { useUserStore } from '@/stores/userStore';
import DarkmodeToggleButton from '@/components/DarkmodeToggleButton.vue';

const userStore = useUserStore();
</script>

<template>
	<header class="m-4 flex justify-between">
		<div class="flex items-center gap-4">
			<img alt="Vue logo" class="logo" src="/images/timebox.svg" width="50" />
			<strong class="hidden sm:block">Timebox</strong>
		</div>

		<nav class="flex items-center gap-4">
			<RouterLink to="/timeboxes" v-if="userStore.isLoggedIn">Timeboxes</RouterLink>
		</nav>

		<div class="flex items-center gap-2">
			<DarkmodeToggleButton />
			<UserDetails />
		</div>
	</header>
</template>

<style scoped>
@keyframes rotate {
	0%,
	96.77% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(720deg);
	}
}

img {
	transition: transform ease-in-out;
	animation: rotate 31s infinite;
}
</style>
