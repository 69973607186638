<script setup lang="ts"></script>

<template>
	<small class="text-red-500 dark:text-red-300">
		<slot />

		&nbsp;
	</small>
</template>

<style scoped></style>
