import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { useUserStore } from '@/stores/userStore';

function onlyLoggedOut() {
	const userStore = useUserStore();
	if (userStore.isLoggedIn) {
		return '/timeboxes';
	}
}

function onlyLoggedIn() {
	const userStore = useUserStore();
	if (!userStore.isLoggedIn) {
		return '/';
	}
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
			beforeEnter: [onlyLoggedOut],
			meta: { title: 'Login' },
		},
		{
			path: '/register',
			name: 'register',
			component: () => import('../views/RegisterView.vue'),
			beforeEnter: [onlyLoggedOut],
			meta: { title: 'Registration' },
		},
		{
			path: '/timeboxes',
			name: 'timeboxes',
			component: () => import('../views/TimeboxesPage.vue'),
			beforeEnter: [onlyLoggedIn],
		},
	],
});

router.afterEach((to, from) => {
	switch (to.name) {
		case 'timeboxes': {
			document.title = 'Timebox';
			break;
		}

		default: {
			document.title = `${to.meta.title?.toString()} - Timebox`;
			break;
		}
	}
});

export default router;
