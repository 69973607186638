<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useMutation } from '@tanstack/vue-query';
import { useUserStore } from '@/stores/userStore';
import type { IApiError } from '@/types/types';
import { login } from '@/utils/timeboxApi';
import FloatLabel from 'primevue/floatlabel';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { z } from 'zod';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import FormError from '@/components/FormError.vue';

const router = useRouter();
const userStore = useUserStore();

const validationSchema = toTypedSchema(
	z.object({
		email: z.string().min(1, { message: 'Required' }),
		password: z.string().min(1, { message: 'Required' }),
	})
);

const { handleSubmit, errors, defineField, setErrors } = useForm({
	validationSchema,
});

const [email, emailAttrs] = defineField('email');
const [password, passwordAttrs] = defineField('password');

const { isPending, mutate } = useMutation<unknown, IApiError>({
	mutationFn: () =>
		login({
			email: email.value!,
			password: password.value!,
		}),
	onSuccess: async () => {
		await userStore.fetchUserInfo();
		await router.push('/timeboxes');
	},
	onError: async (err) => {
		setErrors({
			email: ' ',
			password: err.response.data.message,
		});
	},
});

const onSubmit = handleSubmit(() => {
	mutate();
});
</script>

<template>
	<main class="mx-4 flex grow items-center justify-center">
		<Card class="max-w-96">
			<template #title>Login</template>
			<template #content>
				<form class="flex flex-col gap-6 p-10" @submit="onSubmit">
					<div>
						<FloatLabel>
							<InputText
								class="w-full"
								id="email"
								v-model="email"
								v-bind="emailAttrs"
								type="email"
								:invalid="errors.email !== undefined"
							/>
							<label for="email">Email</label>
						</FloatLabel>

						<FormError>
							{{ errors.email }}
						</FormError>
					</div>

					<div>
						<FloatLabel>
							<Password
								input-class="w-full"
								id="password"
								v-model="password"
								v-bind="passwordAttrs"
								toggleMask
								:feedback="false"
								:invalid="errors.password !== undefined"
							/>
							<label for="password">Password</label>
						</FloatLabel>

						<FormError>
							{{ errors.password }}
						</FormError>
					</div>

					<Button type="submit" label="Login" icon="pi pi-sign-in" :disabled="isPending" />
				</form>
			</template>
		</Card>
	</main>
</template>
