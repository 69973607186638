import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { getUser } from '@/utils/timeboxApi';
import router from '@/router';

export const useUserStore = defineStore('user', () => {
	const isLoggedIn = useLocalStorage('isLoggedIn', false);
	const user = ref<{
		name: string;
		email: string;
	} | null>(null);

	if (isLoggedIn.value) {
		fetchUserInfo();
	}

	async function fetchUserInfo() {
		try {
			const res = await getUser();

			isLoggedIn.value = true;
			user.value = {
				name: res.data.name,
				email: res.data.email,
			};
		} catch (err) {
			isLoggedIn.value = false;
			user.value = null;

			await router.push('/');
		}
	}

	return {
		isLoggedIn,
		user,

		fetchUserInfo,
	};
});
